<template>
  <main class="main">
    <section class="section">
      <div class="container">
        <div class="page__wrap">
          <template v-if="!loading">
            <div class="page-session">
              <div class="material-title">
                <span class="circle_initial">
                  {{ material.name[0] }}
                </span>
                <span>{{ material.name }}</span>
              </div>
              <div class="separator"></div>
              <Certification
                :item-id="city_company_item_id"
                :material.sync="material"
              />
              <div class="separator"></div>
              <div class="sidebar__nav">
                <router-link
                  class="sidebar__nav--anchor"
                  title="Solicitação de coletas"
                  :to="`/solicitar-coleta/${material.id}`"
                >
                  <i class="icon icon-request"></i>

                  Solicitação de coletas
                </router-link>
                <router-link
                  :to="`/material/${material.id}`"
                  class="sidebar__nav--anchor current"
                  title="Pontos de coleta"
                >
                  <i class="icon icon-request"></i>
                  Pontos de coleta
                </router-link>
              </div>
            </div>
            <div class="page-session items-container">
              <div class="items-container-header">
                <div>
                  <h2>Pontos de coleta:</h2>
                  <h3>Cidades atendidas por solicitação</h3>
                </div>
                <div>
                  <add-city
                    :unity_id.sync="material.unity_id"
                    :item_id.sync="material.id"
                    @saved="getData(false)"
                  >
                  </add-city>
                </div>
              </div>
              <div class="alert alert-info">
                <i class="fa fa-exclamation-circle"></i>
                Marque abaixo as cidades que possuem pontos de coleta.
              </div>

              <div class="cities-content">
                <div
                  class="city-item"
                  v-for="city in material.company_items"
                  :key="city.city_id"
                  :class="{
                    active: city.city_id === activeCity,
                    'cursor-not-allowed': !city.approved
                  }"
                  @click="handleCitySelect(city)"
                >
                  <div class="city-title">
                    <i
                      v-show="city.city_id === activeCity"
                      class="fas fa-check-circle"
                    ></i>
                    <span
                      class="inactive-city"
                      v-show="city.city_id !== activeCity"
                    ></span>
                    {{ `${city.city} - ${city.province}` }}
                    <span class="await-approve" v-show="!city.approved">
                      Aguardando aprovação
                    </span>
                    <delete-city
                      :city-id="city.city_company_item_id"
                      @deleted="getData(false)"
                    />
                  </div>
                  <div class="city-addresses">
                    <div
                      v-if="!city.collectors.length"
                      class="city-address no-collect-point"
                    >
                      <small class="no-collect-point">
                        Não há pontos de coleta cadastrados para a cidade de
                        {{ city.city }}
                      </small>
                    </div>
                    <div
                      class="city-address"
                      v-for="ca in city.collectors"
                      :key="ca.id"
                    >
                      <div>
                        {{ ca.description }}
                        <small>
                          {{
                            `${ca.street}, ${ca.number} - ${ca.neighborhood} - ${ca.city} - ${ca.province}`
                          }}
                        </small>
                      </div>
                      <div>
                        <i
                          @click="deleteAddress(ca.id)"
                          class="fa fa-trash-alt"
                        ></i>
                      </div>
                    </div>
                    <div class="city-address">
                      <small class="add-collect-point" @click="addNewAddress">
                        <i class="fas fa-plus"></i> Adicionar ponto de coleta
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <loading v-else />
        </div>
      </div>
    </section>
    <AddAddress
      v-model="addAddressModal"
      :city-id="activeCity"
      :item-id="material.id"
    />
  </main>
</template>

<script>
import swal from 'sweetalert'

export default {
  components: {
    loading: () => import('../../components/loading.vue'),
    AddCity: () => import('./add-city.vue'),
    AddAddress: () => import('./add-collect-point/index.vue'),
    DeleteCity: () => import('./delete-city.vue'),
    Certification: () => import('./certification')
  },
  data: () => ({
    activeCity: '',
    material: {},
    loading: true,
    addAddressModal: false,
    collectors: [],
    city_company_item_id: ''
  }),
  methods: {
    async getData (loading = true) {
      const id = this.$route.params.id
      if (!id) this.$router.back()
      if (loading) this.loading = true

      const url = `materials/${id}/cities`
      const { data } = await this.$http.get(url)
      this.material = data
      if (!this.activeCity) this.selectFirstCity()
      this.loading = false
    },
    selectFirstCity () {
      for (const city of this.material.company_items) {
        if (!city.approved) continue
        this.activeCity = city.city_id
        return
      }
    },
    handleCitySelect (city) {
      if (!city || !city.approved) return false
      this.city_company_item_id = city.city_company_item_id
      this.activeCity = city.city_id
    },
    addNewAddress () {
      this.addAddressModal = true
    },
    async getCollectors () {
      const url = 'check/getCollectors'
      const { data } = await this.$http.post(url)
      this.collectors = data.data
      const [first] = this.collectors
      if (!first) return
      this.collectorId = first.id
    },
    async deleteAddress (id) {
      const url = `materials/${id}/cities`

      try {
        await this.$http.delete(url)

        swal({
          title: 'Sucesso!',
          text: 'Ponto de coleta deletado com sucesso.',
          icon: 'success',
          buttons: false,
          timer: 2000
        })
        this.getData(false)
        this.getCollectors()
      } catch (error) {
        swal({
          title: 'Ooops!',
          text: 'Erro ao deletar',
          icon: status >= 500 ? 'error' : 'warning'
        })
      }
    }
  },
  created () {
    this.getData()
    this.getCollectors()
    this.city_company_item_id = this.$route.query.item
  },

  watch: {
    addAddressModal (val) {
      if (!val) this.getData(false)
    }
  }
}
</script>

<style lang="sass" scoped>
@import './style'
</style>
<style lang="sass">
.await-approve, .approved
  margin-left: 1rem
  font-size: 0.9rem
  padding: 0.4rem 0.75rem
  border-radius: 1rem
  text-transform: none

.await-approve
  background-color: #fff3e2
  color: #d87420

.approved
  background-color: #e6f7ec
  color: #16b666
</style>
